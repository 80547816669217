.pb-s { padding-bottom: @spacer * 0.5 !important;  }
.pb-m { padding-bottom: @spacer * 1 !important;  }
.pb-l { padding-bottom: @spacer * 2 !important;  }
.pa-s { padding: @spacer * 0.5 !important; }
.pa-m { padding: @spacer * 1 !important; }
.pa-l { padding: @spacer * 2 !important; }

.mr-s { margin-right: @spacer * 0.5 !important;  }
.mr-m { margin-right: @spacer * 1 !important;  }
.mr-l { margin-right: @spacer * 2 !important;  }
.mb-s { margin-bottom: @spacer * 0.5 !important;  }
.mb-m { margin-bottom: @spacer * 1 !important;  }
.mb-l { margin-bottom: @spacer * 2 !important;  }
