small {
  opacity: .6;
}
.text-muted{
  opacity: .6;
}
.clear{
  display: block;
  overflow: hidden;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.y-center{
  display: flex;
  align-items: center;
}
.block{
  display: block;
}
.inline {
  display: inline;
}
.none{
  display: none;
}
.b-white {
  border-color: #ffffff;
}
.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-v {
  height: 100vh;
}

.h-v-5 {
  height: 50vh;
}


.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.w-40 {
  width:  40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
}