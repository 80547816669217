.text{
  font-size: 1rem;
}
.text-2x{
  font-size: 2rem;
}
.text-3x{
  font-size: 3rem;
}
.text-4x{
  font-size: 4rem;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-danger,
.text-danger-hover a:hover {
  color: #f44455 !important;
}
.text-dark,
.text-dark-hover a:hover {
  color: #2e3e4e !important;
}
.text-info,
.text-info-hover a:hover {
  color: #6887ff !important;
}
.text-success,
.text-success-hover a:hover {
  color: #6cc788 !important;
}
.text-blue,
.text-blue-hover a:hover {
  color: #2196f3 !important; }
