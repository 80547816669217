ul.icons-list{
  list-style: none;
  overflow: hidden;
  li{
    float: left;
    width: 10%;
    text-align: center;
    list-style: none;
    cursor: pointer;
    height: 100px;
    transition: all .3s;
    background-color: #fff;
    &:hover{
      background-color: #cccccc;
      color: #fff;
    }
    i{
      margin: 16px 0 10px;
    }
    span{
      display: block;
      text-align: center;
    }
  }
}