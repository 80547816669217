.table-operations {
  margin-bottom: 16px;
  > button {
    margin-right: 8px;
  }
}

.editable-row-text {
  padding: 5px;
}

.editable-row-operations a {
  margin-right: 8px;
}